<!-- Mashead header-->
<app-masthead></app-masthead>
<!-- Quote/testimonial -->
<app-testimonial></app-testimonial>
<!-- App features section-->
<app-features></app-features>
<!-- Basic features section-->
<app-basic-features></app-basic-features>
<!-- Call to action section-->
<app-call-to-action></app-call-to-action>
<!-- App badge section-->
<app-app-badges></app-app-badges>
