import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public originUrl: string = environment.originUrl;
  public apiUrl: string = environment.apiUrl;

  constructor() {
    this.loadJSON('/config.json', (res: any) => {
      const config: ConfigService = res;

      this.originUrl = config.originUrl;
      this.apiUrl = config.apiUrl;
    }, function (error: any) {
      console.log('Error Loading Configuration');
      console.log(error.responseText);
    });
  }

  loadJSON(path: any, success: any, error: any) {
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {

      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          if (success)
            success(JSON.parse(xhr.responseText));
        } else {
          if (error)
            error(xhr);
        }
      }
    };
    xhr.open("GET", path, false);
    xhr.send();
  }
}
