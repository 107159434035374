import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ASASInterceptor implements HttpInterceptor {

  constructor() { }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req);
    //debugger;
    //let modifiedHeaders = req.headers;
    //if (req.headers.get('enctype') !== 'multipart/form-data')
    //  modifiedHeaders = req.headers.set('Content-Type', 'multipart/form-data');

    //const clonedReq = req.clone({ headers: modifiedHeaders });
    //return next.handle(clonedReq);

  }
  
}
