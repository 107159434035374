<div class="modal fade" id="feedbackModal" tabindex="-1" aria-labelledby="feedbackModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header bg-gradient-primary-to-secondary p-4">
        <h5 class="modal-title font-alt text-white col align-self-start" id="feedbackModalLabel">رأيك يهمنا</h5>
        <button class="btn-close btn-close-white" type="button" (click)="closeModal()"  aria-label="Close"></button>
      </div>
      <div class="modal-body border-0 p-4">
        <form [formGroup]="fgUsersMessage">
          <!-- Name input-->
          <div class="form-floating mb-3" *ngIf="SubmitOperationStatus==-1">
            <input class="form-control pull-right" id="name" formControlName="FullName" type="text" placeholder="برجاء ادخال الاسم..." />
            <label for="name">الاسم بالكامل</label>
            <div *ngIf="fgUsersMessage.controls['FullName'].touched && fgUsersMessage.controls['FullName'].invalid">
              <div class="invalid-feedback" *ngIf="fgUsersMessage.controls['FullName'].errors.required">يجب إدخال الاسم</div>
              <div class="invalid-feedback" *ngIf="fgUsersMessage.controls['FullName'].errors.maxLength">يجب أن لا يزيد الاسم عن 200 حرف</div>
            </div>
          </div>
          <!-- Email address input-->
          <div class="form-floating mb-3" *ngIf="SubmitOperationStatus==-1">
            <input class="form-control" id="email" type="email" formControlName="Email" placeholder="name@example.com" />
            <label for="email">البريد الإلكتروني</label>
            <div *ngIf="fgUsersMessage.controls['Email'].touched && fgUsersMessage.controls['Email'].invalid">
              <div class="invalid-feedback" *ngIf="fgUsersMessage.controls['Email'].errors.required">يجب إدخال البريد الإلكتروني.</div>
              <div class="invalid-feedback" *ngIf="fgUsersMessage.controls['Email'].errors.email">البريد الإلكتروني غير صحيح.</div>
              <div class="invalid-feedback" *ngIf="fgUsersMessage.controls['Email'].errors.maxLength">يجب أن لا يزيد الاسم عن 200 حرف</div>
            </div>
          </div>
          <!-- Phone number input-->
          <div class="form-floating mb-3" *ngIf="SubmitOperationStatus==-1">
            <input class="form-control" id="phone" formControlName="Phone" type="tel" placeholder="05XXXXXXXX" />
            <label for="phone">رقم الهاتف</label>
            <div *ngIf="fgUsersMessage.controls['Phone'].touched && fgUsersMessage.controls['Phone'].invalid">
              <div class="invalid-feedback" *ngIf="fgUsersMessage.controls['Phone'].errors.required">يجب إدخال رقم الهاتف.</div>
              <div class="invalid-feedback" *ngIf="fgUsersMessage.controls['Phone'].errors.pattern">رقم الهاتف غير صحيح.</div>
              <div class="invalid-feedback" *ngIf="fgUsersMessage.controls['Phone'].errors.maxLength">يجب أن لا يزيد الاسم عن 12 حرف</div>
            </div>
          </div>
          <!-- Message input-->
          <div class="form-floating mb-3" *ngIf="SubmitOperationStatus == -1">
            <textarea class="form-control" id="message" formControlName="Message" type="text" placeholder="برجاء كتابة الموضوع..." style="height: 10rem"></textarea>
            <label for="message">الموضوع</label>
            <div *ngIf="fgUsersMessage.controls['Message'].touched && fgUsersMessage.controls['Message'].invalid">
              <div class="invalid-feedback" *ngIf="fgUsersMessage.controls['Message'].errors.required">يجب إدخال نص.</div>
            </div>
          </div>
          <!-- Submit success message-->
          <!---->
          <!-- This is what your users will see when the form-->
          <!-- has successfully submitted-->
          <div class="successmessage" id="submitSuccessMessage" *ngIf="SubmitOperationStatus==1">
            <div class="text-center mb-3">
              <div class="fw-bolder">تم إستلام رسالتك و سيتم التواصل معك في أقرب وقت ممكن.<br/>كما يمكنك التواصل معنا من خلال ارسال رسالة بريد إلكتروني علي البريد التالي: <a href="mailto:info@asas-app.com?subject=رأيك يهمنا&body=الموضوع">info@asas-app.com</a></div>
            </div>
          </div>
          <!-- Submit error message-->
          <!---->
          <!-- This is what your users will see when there is-->
          <!-- an error submitting the form-->
          <div class="failurmessagee" id="submitErrorMessage" *ngIf="SubmitOperationStatus==0">
              <div class="fw-bolder">تم إستلام رسالتك و سيتم التواصل معك في أقرب وقت ممكن ,<br/>كما يمكنك التواصل معنا من خلال ارسال رسالة بريد إلكتروني علي البريد التالي: <a href="mailto:info@asas-app.com?subject=رأيك يهمنا&body=الموضوع">info@asas-app.com</a></div>
              <div class="text-center text-danger mb-3">عفوا، حدث خطأ أثناء حفظ رسالتك برجاء المحاوله مره أخري.<br />كما يمكنك التواصل معنا من خلال ارسال رسالة بريد إلكتروني علي البريد التالي: <a href="mailto:info@asas-app.com?subject=رأيك يهمنا&body=الموضوع">info@asas-app.com</a></div>
          </div>
          <!-- Submit Button-->
          <div class="d-grid" *ngIf="SubmitOperationStatus==-1">
            <button class="btn btn-primary rounded-pill btn-lg" id="submitButton" type="button" (click)="submitUserMessageInfo()" [disabled]="!fgUsersMessage.valid">الإرسال</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
