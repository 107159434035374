<aside class="text-center bg-gradient-primary-to-secondary">
  <div class="container px-5">
    <div class="row gx-5 justify-content-center">
      <div class="col-xl-8">
        <div class="h2 fs-1 text-white mb-4">مع اساس تستطيع تنميه قدراتك أنت و عائلتك من خلال الوصول لأكبر عدد من مقدمي الخدمات (التعليمية - الرياضية - الترفيهية - وغيرها)</div>
        <img src="assets/img/asaslogoinverted.jpeg" alt="..." style="height: 250px;" />
      </div>
    </div>
  </div>
</aside>
