<section class="bg-light">
  <div class="container px-5">
    <div class="row gx-5 align-items-center justify-content-center justify-content-lg-between">
      <div class="col-12 col-lg-5">
        <h2 class="display-4 lh-1 mb-4">شبكة فريده من شركائنا</h2>
        <p class="lead fw-normal text-muted mb-5 mb-lg-0">لدينا أكبر شبكة من الشركاء مقدمي الخدمات (التعليمية و الرياضية و غيرها من الأنشطة ذات الأهتمام) و التي تقدم خصومات و عروض حصرية لمستخدمي التطبيق.</p>
        <br />
        <p class="lead fw-normal text-muted mb-5 mb-lg-0">يقوم أساس بمساعدة المستخدم في أختيار ما يناسبة من البرامج المتاحه للمؤسسات من خلال طرق بحث سهلة و مبتكرة و تقييمات موضوعية لكل مؤسسة من خلال المستخدمين الذين قاموا بالتعامل مع المؤسسات.</p>
      </div>
      <div class="col-sm-8 col-md-6">
        <div class="px-5 px-sm-0"><img class="img-fluid rounded-circle" src="assets/img/asasschoolnetwork.png" alt="..." /></div>
      </div>
    </div>
  </div>
</section>
