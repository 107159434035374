import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfigService } from '../../../services/config.service';
import { UsersMessagesService } from '../../../services/usersmessages.service';
//import $ from 'jquery'
declare var window: any;


@Component({
  selector: 'app-feed-back',
  templateUrl: './feed-back.component.html',
  styleUrls: ['./feed-back.component.css']
})
export class FeedBackComponent implements OnInit {
  public fgUsersMessage: UntypedFormGroup;
  public arErrors: Array<string> = [];
  public SubmitOperationStatus = -1;
  formModal: any;

  constructor(
    private fb: UntypedFormBuilder,
    private _configService: ConfigService,
    private _usersMessages: UsersMessagesService
  ) {

    const mobilePattern = /^05{1}[0-9]{8,9}$/;

    this.fgUsersMessage = fb.group({
      'Id':[0],
      'FullName': [null, Validators.compose([Validators.required, Validators.maxLength(200)])],
      'Email': [null, Validators.compose([Validators.required, Validators.maxLength(200), Validators.email])],
      'Phone': [null, Validators.compose([Validators.required, Validators.maxLength(12), Validators.pattern(mobilePattern)])],
      'Message': [null, Validators.required],
    });
  }

  ngOnInit(): void {
    debugger;
    this.formModal = new window.bootstrap.Modal(
      document.getElementById('feedbackModal')
    );

    //this.formModal.show();
    
  }

  submitUserMessageInfo() {
    debugger;
    this.arErrors = [];
    this._usersMessages.insert(this.fgUsersMessage.value).subscribe(data => {
      this.SubmitOperationStatus = 1;
    }, error => {
      this.SubmitOperationStatus = 0;
    });
  }

  closeModal() {
    debugger;
    this.fgUsersMessage.controls['FullName'].setValue('');
    this.fgUsersMessage.controls['Email'].setValue('');
    this.fgUsersMessage.controls['Phone'].setValue('');
    this.fgUsersMessage.controls['Message'].setValue('');
    this.SubmitOperationStatus = -1;

    this.formModal.hide();
  }

}
