import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class UsersMessagesService {
  constructor(private http: HttpClient,private _configService: ConfigService) {}

  public get(_UsersMessagesID: string): Observable<any> {
    return this.http.get(`${this._configService.apiUrl}/api/UsersMessages/` + _UsersMessagesID);
  }

  public getAll(): Observable<any> {
    
    return this.http.get(`${this._configService.apiUrl}/api/UsersMessages`);
  }
  
  public insert(_UsersMessages: any): Observable<any> {
    debugger;
    return this.http.post(`${this._configService.apiUrl}/api/UsersMessages`, _UsersMessages);
  }

  public update(_UsersMessages: any): Observable<any> {
    return this.http.put(`${this._configService.apiUrl}/api/UsersMessages`, _UsersMessages);
  }

  public delete(_UsersMessagesID: string): Observable<any> {
    return this.http.delete(`${this._configService.apiUrl}/api/UsersMessages/` + _UsersMessagesID);
  }
}
