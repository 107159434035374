<section id="download" class="cta">
  <div class="cta-content">
    <div class="container px-5">
      <h2 class="text-white display-1 lh-1 mb-4">
        لا تتردد
        <br />
        قم بتحميل التطبيق و أبدأ البحث
      </h2>
      <a class="btn btn-outline-light py-3 px-4 rounded-pill" style="float:left;" [routerLink]="['/DownloadApp']">قم بالتحميل مجاناً</a>
    </div>
  </div>
</section>
