<header class="masthead">
    <div class="container px-5">
        <div class="row gx-5 align-items-center">
            <div class="col-lg-12">
                <!-- Mashead text and app badges-->
                <div class="mb-5 mb-lg-0 text-center text-lg-start">
                    <h1 class="display-1 lh-1 mb-3">سياسة الخصوصية</h1>
                    <p class="lead fw-normal text-muted mb-5">الرجاء قراءة مع التفاهم قبل استخدام الخدمات التي نقدمها</p>

                    <h1 class="display-7 lh-1 mb-3">سياسة الخصوصية لـ ASAS (اساس)</h1>
                    <p class="lead fw-normal text-muted mb-5">
                        في ASAS (اساس) ، الذي يمكن الوصول إليه من ASAS (اساس) ، تتمثل إحدى أولوياتنا الرئيسية في خصوصية زوارنا. تحتوي وثيقة سياسة الخصوصية هذه على أنواع المعلومات التي يتم جمعها وتسجيلها بواسطة تطبيق Asas وكيفية استخدامنا لها.
                    </p>
                    <p class="lead fw-normal text-muted mb-5">
                        إذا كانت لديك أسئلة إضافية أو تحتاج إلى مزيد من المعلومات حول سياسة الخصوصية الخاصة بنا ، فلا تتردد في الاتصال بنا.
                    </p>
                    <p class="lead fw-normal text-muted mb-5">
                        تنطبق سياسة الخصوصية هذه فقط على أنشطتنا عبر الإنترنت وهي صالحة لزوار موقعنا الإلكتروني فيما يتعلق بالمعلومات التي شاركوها و / أو يجمعونها في تطبيق Asas. لا تنطبق هذه السياسة على أي معلومات يتم جمعها في وضع عدم الاتصال أو عبر قنوات أخرى غير هذا الموقع.
                    </p>


                    <h1 class="display-7 lh-1 mb-3">موافقة</h1>
                    <p class="lead fw-normal text-muted mb-5">
                        باستخدام موقعنا ، فإنك بذلك توافق على سياسة الخصوصية الخاصة بنا وتوافق على شروطها.
                    </p>

                    <h1 class="display-7 lh-1 mb-3">المعلومات التي نجمعها</h1>
                    <p class="lead fw-normal text-muted mb-5">سيتم توضيح المعلومات الشخصية التي يُطلب منك تقديمها ، وأسباب مطالبتك بتقديمها ، في الوقت الذي نطلب منك فيه تقديم معلوماتك الشخصية.</p>
                    <p class="lead fw-normal text-muted mb-5">إذا اتصلت بنا مباشرة ، فقد نتلقى معلومات إضافية عنك مثل اسمك وعنوان بريدك الإلكتروني ورقم هاتفك ومحتويات الرسالة و / أو المرفقات التي قد ترسلها إلينا وأي معلومات أخرى قد تختار تقديمها.</p>
                    <p class="lead fw-normal text-muted mb-5">عند التسجيل للحصول على حساب ، قد نطلب معلومات الاتصال الخاصة بك ، بما في ذلك عناصر مثل الاسم واسم الشركة والعنوان وعنوان البريد الإلكتروني ورقم الهاتف.</p>

                    <h1 class="display-7 lh-1 mb-3">كيف نستخدم معلوماتك</h1>
                    <ul class="lead fw-normal text-muted mb-5">
                        <li> توفير وتشغيل وصيانة موقعنا</li>
                        <li> تحسين وتخصيص وتوسيع موقعنا</li>
                        <li> فهم وتحليل كيفية استخدامك لموقعنا</li>
                        <li> تطوير منتجات وخدمات وميزات ووظائف جديدة</li>
                        <li> التواصل معك ، إما بشكل مباشر أو من خلال أحد شركائنا ، بما في ذلك لخدمة العملاء ، لتزويدك بالتحديثات والمعلومات الأخرى المتعلقة بالموقع ولأغراض تسويقية وترويجية</li>
                        <li> أرسل لك رسائل البريد الإلكتروني</li>
                        <li> البحث عن ومنع الاحتيال</li>
                    </ul>

                    <h1 class="display-7 lh-1 mb-3">ملفات الدخول</h1>
                    <p class="lead fw-normal text-muted mb-5">
                        ملفات الدخول
                        يتبع ASAS (اساس) إجراءً قياسيًا لاستخدام ملفات السجل. تسجل هذه الملفات الزوار عندما يزورون مواقع الويب. تقوم جميع شركات الاستضافة بذلك وجزء من تحليلات خدمات الاستضافة. تتضمن المعلومات التي يتم جمعها بواسطة ملفات السجل عناوين بروتوكول الإنترنت (IP) ونوع المستعرض وموفر خدمة الإنترنت (ISP) وختم التاريخ والوقت وصفحات الإحالة / الخروج وربما عدد النقرات. هذه ليست مرتبطة بأي معلومات لتحديد الهوية الشخصية. الغرض من المعلومات هو تحليل الاتجاهات وإدارة الموقع وتتبع حركة المستخدمين على الموقع وجمع المعلومات الديموغرافية.
                    </p>


                    <h1 class="display-7 lh-1 mb-3">سياسات خصوصية شركاء الإعلانات</h1>
                    <p class="lead fw-normal text-muted mb-5">يمكنك الرجوع إلى هذه القائمة للعثور على سياسة الخصوصية لكل من شركاء الإعلان لـ ASAS (اساس).</p>
                    <p class="lead fw-normal text-muted mb-5">تستخدم خوادم الإعلانات الخارجية أو شبكات الإعلانات تقنيات مثل ملفات تعريف الارتباط أو جافا سكريبت أو إشارات الويب المستخدمة في الإعلانات والروابط الخاصة بكل منها والتي تظهر على ASAS (اساس) ، والتي يتم إرسالها مباشرة إلى متصفح المستخدمين. يتلقون عنوان IP الخاص بك تلقائيًا عند حدوث ذلك. تُستخدم هذه التقنيات لقياس فعالية حملاتهم الإعلانية و / أو لتخصيص محتوى الإعلان الذي تراه على مواقع الويب التي تزورها.</p>
                    <p class="lead fw-normal text-muted mb-5">لاحظ أن ASAS (اساس) ليس لديه حق الوصول أو التحكم في ملفات تعريف الارتباط هذه التي يستخدمها معلنون من الجهات الخارجية.</p>

                    <h1 class="display-7 lh-1 mb-3">سياسات خصوصية الطرف الثالث</h1>
                    <p class="lead fw-normal text-muted mb-5">لا تنطبق سياسة الخصوصية الخاصة بـ ASAS (اساس) على المعلنين أو المواقع الإلكترونية الأخرى. وبالتالي ، فإننا ننصحك بالرجوع إلى سياسات الخصوصية الخاصة بخوادم إعلانات الجهات الخارجية للحصول على معلومات أكثر تفصيلاً. قد يتضمن ممارساتهم وتعليماتهم حول كيفية الانسحاب من بعض الخيارات.</p>
                    <p class="lead fw-normal text-muted mb-5">يمكنك اختيار تعطيل ملفات تعريف الارتباط من خلال خيارات المتصفح الفردية الخاصة بك. لمعرفة المزيد من المعلومات التفصيلية حول إدارة ملفات تعريف الارتباط مع متصفحات الويب المحددة ، يمكن العثور عليها في مواقع الويب الخاصة بالمتصفحات.</p>

                    <h1 class="display-8 lh-1 mb-3">حقوق خصوصية CCPA (لا تبيع معلوماتي الشخصية)</h1>
                    <p class="lead fw-normal text-muted mb-5">لبموجب قانون حماية خصوصية المستهلك في كاليفورنيا ، من بين حقوق أخرى ، يحق للمستهلكين في كاليفورنيا:</p>
                    <ul class="lead fw-normal text-muted mb-5">
                        <li> اطلب من الشركة التي تجمع البيانات الشخصية للمستهلك أن تكشف عن فئات وأجزاء معينة من البيانات الشخصية التي جمعتها الشركة عن المستهلكين.</li>
                        <li> اطلب من شركة ما حذف أي بيانات شخصية عن المستهلك جمعتها الشركة.</li>
                        <li> اطلب من الشركة التي تبيع البيانات الشخصية للمستهلك ، ألا تبيع البيانات الشخصية للمستهلك.</li>
                        <li> إذا قمت بتقديم طلب ، فلدينا شهر واحد للرد عليك. إذا كنت ترغب في ممارسة أي من هذه الحقوق ، يرجى الاتصال بنا info@asas-app.com.</li>
                    </ul>

                    <h1 class="display-7 lh-1 mb-3">حقوق حماية بيانات GDPR</h1>
                    <p class="lead fw-normal text-muted mb-5">نود التأكد من أنك على دراية كاملة بجميع حقوق حماية البيانات الخاصة بك. يحق لكل مستخدم ما يلي:</p>
                    <ul class="lead fw-normal text-muted mb-5">
                        <li> الحق في الوصول - يحق لك طلب نسخ من بياناتك الشخصية. قد نفرض عليك رسومًا رمزية مقابل هذه الخدمة.</li>
                        <li> الحق في المسح - يحق لك طلب مسح بياناتك الشخصية ، في ظل ظروف معينة.</li>
                        <li> الحق في تقييد المعالجة - يحق لك طلب تقييد معالجة بياناتك الشخصية ، في ظل ظروف معينة.</li>
                        <li> الحق في الاعتراض على المعالجة - يحق لك الاعتراض على معالجتنا لبياناتك الشخصية ، في ظل ظروف معينة.</li>
                        <li> الحق في نقل البيانات - يحق لك أن تطلب منا نقل البيانات التي جمعناها إلى مؤسسة أخرى ، أو إليك مباشرةً ، في ظل ظروف معينة.</li>
                        <li> إذا قمت بتقديم طلب ، فلدينا شهر واحد للرد عليك. إذا كنت ترغب في ممارسة أي من هذه الحقوق ، يرجى الاتصال بنا info@asas-app.com.</li>
                    </ul>


                    <h1 class="display-7 lh-1 mb-3">معلومات الأطفال</h1>
                    <p class="lead fw-normal text-muted mb-5">جزء آخر من أولوياتنا هو إضافة حماية للأطفال أثناء استخدام الإنترنت. نشجع الآباء والأوصياء على مراقبة نشاطهم عبر الإنترنت والمشاركة فيه و / أو مراقبته وتوجيهه.</p>
                    <p class="lead fw-normal text-muted mb-5">لا تجمع أساس (اساس) عن قصد أي معلومات تعريف شخصية من الأطفال دون سن 13 عامًا. إذا كنت تعتقد أن طفلك قدم هذا النوع من المعلومات على موقعنا ، فنحن نشجعك بشدة على الاتصال بنا على الفور وسنبذل قصارى جهدنا لإزالة هذه المعلومات على الفور من سجلاتنا.</p>

                    <h1 class="display-7 lh-1 mb-3">سلطات الادعاء والإجراءات القانونية</h1>
                    <p class="lead fw-normal text-muted mb-5">لسوء الحظ، قد لا يتصرف القليل من عملائنا ومقدمي الخدمات بإنصاف ويريدون إلحاق الضرر بنا. وفي هذه الحالات، لسنا ملزمين فقط بتسليم البيانات الشخصية بسبب الالتزامات القانونية، بل من مصلحتنا أيضًا منع الضرر وفرض دعواتنا ورفض الدعاوى غير المبررة.</p>

                    <h1 class="display-7 lh-1 mb-3">التغييرات على سياسة الخصوصية هذه</h1>
                    <p class="lead fw-normal text-muted mb-5">قد نقوم بتحديث سياسة الخصوصية الخاصة بنا من وقت لآخر. وبالتالي ، ننصحك بمراجعة هذه الصفحة بشكل دوري لأية تغييرات. سنخطرك بأي تغييرات عن طريق نشر سياسة الخصوصية الجديدة على هذه الصفحة. تسري هذه التغييرات فور نشرها على هذه الصفحة.</p>

                    <h1 class="display-7 lh-1 mb-3">اتصل بنا</h1>
                    <p class="lead fw-normal text-muted mb-5">إذا كان لديك أي أسئلة أو اقتراحات حول سياسة الخصوصية الخاصة بنا ، فلا تتردد في الاتصال بنا info@asas-app.com أو الدخول علي الموقع asas-app.com.</p>

                    <app-download-app></app-download-app>
                </div>
            </div>
        </div>
    </div>
</header>
