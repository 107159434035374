        <!-- Mashead header-->
<header class="masthead">
  <div class="container px-5">
    <div class="row gx-5 align-items-center">
      <div class="col-lg-12">
        <!-- Mashead text and app badges-->
          <div class="mb-5 mb-lg-0 text-center text-lg-start">
              <h1 class="display-1 lh-1 mb-3">التحميلات</h1>
              <p class="lead fw-normal text-muted mb-5">هنا يمكنك العثور على روابط التحميل الخاصة بالتطبيق علي متجر الأبل و متجر الأندرويد</p>

              <p class="lead fw-normal text-muted mb-5">قم بتحميل التطبيق و أبدأ البحث</p>
              <app-download-app></app-download-app>
          </div>
      </div>
    </div>
  </div>
</header>
