        <!-- Mashead header-->
<header class="masthead">
  <div class="container px-5">
    <div class="row gx-5 align-items-center">
      <div class="col-lg-12">
        <!-- Mashead text and app badges-->
        <div class="mb-5 mb-lg-0 text-center text-lg-start">
          <h1 class="display-1 lh-1 mb-3">الأسئلة الشائعة</h1>
          <p class="lead fw-normal text-muted mb-5">هنا يمكنك العثور على الأسئلة الأكثر شيوعًا</p>

          <h1 class="display-8 lh-1 mb-3">الأسئلة العامة</h1>
          <h1 class="display-7 lh-1 mb-3">ما هو اساس</h1>
          <p class="lead fw-normal text-muted mb-5">اساس هو خدمة للبحث عن الخدمات و التعليمية و الرياضية و غيرها من البرامج من خلال الانترنت، بامكانك البحث خلال بضع خطوات جداً بسيطة و سريعة بخلاف الطريقة التقليدية بالهاتف ١- حدد عنوانك ٢- إختر البرنامج المفضل ٣- إطلب الخدمات بضغطة زر.</p>

          <h1 class="display-7 lh-1 mb-3">هل اساس شركة لتقديم الخدمات التعليمية و الرياضية و غيرها من الخدمات؟</h1>
          <p class="lead fw-normal text-muted mb-5">
            اساس تقوم بخدمة البحث عن مقدمي الخدمات التعليمية و الرياضية و غيرها من الخدمات كما اننا نعمل كوسيط بين المستخدم و المؤسسات التي تقوم بتقديم تلك الخدمات. نقوم بتحويل طلبك للمؤسسات عبر الإنترنت بشكل تلقائي لتسهيل عملية البحث و الحجز.

            <h1 class="display-7 lh-1 mb-3">ما هي ساعات عمل اساس</h1>
          <p class="lead fw-normal text-muted mb-5">نحن متواجدين 7/24 كما تعتمد أوقات التواصل على الاوقات التي يتم تحديدها من قبل إدارات المؤسسات.</p>

          <h1 class="display-7 lh-1 mb-3">أين تقع مكاتب اساس</h1>
          <p class="lead fw-normal text-muted mb-5">لا يوجد مكتب لاساس انما هو تطبيق يدار من خلال شبكة الأنترنت؟</p>

          <h1 class="display-8 lh-1 mb-3">الأسئلة المتعلقة بالطلب</h1>
          <h1 class="display-7 lh-1 mb-3">هل يجب علي التسجيل لأتمكن من الحجز؟</h1>
          <p class="lead fw-normal text-muted mb-5">نعم، يجب التسجيل بالتطبيق لاتمام عملية الحجز حيث يتطلب وجود رقم جوال فعّال و عنوان و بريد إلكتروني للتواصل.</p>

          <h1 class="display-7 lh-1 mb-3">هل يوجد رسوم على الخدمة؟</h1>
          <p class="lead fw-normal text-muted mb-5">اساس تقدم خدمة مجانية. ولا يوجد أي رسوم لا يقوم بتحميل العملاء أي رسوم إضافية مقابل الخدمة. المبلغ المدفوع من المستخدم تكون للمؤسسات و ذلك مقابل الخدمات المقدمه من تلك المؤسسات.</p>

          <h1 class="display-7 lh-1 mb-3">كيف يمكنني معرفة و متابعة حالة طلبي؟</h1>
          <p class="lead fw-normal text-muted mb-5">يمكنك متابعة الطلب من خلال التطبيق الخاص بنا، عبر الضغط على سجل الطلبات. </p>

          <h1 class="display-7 lh-1 mb-3">هل يوجد تطبيق خاص بالهواتف الذكية؟</h1>
          <p class="lead fw-normal text-muted mb-5">نعم، يوجد تطبيق خاص باساس لأجهزة الأبل و الأندرويد، يمكنك تحميلها من خلال متجر التطبيقات من خلال البحث بكلمة ASAS أو بإمكانك تحميل التطبيق من خلال الرابط التالي: Https://www.asas-app.com</p>

          <h1 class="display-7 lh-1 mb-3">هل هناك نسخة للموقع خاصة بالهواتف الذكية؟</h1>
          <p class="lead fw-normal text-muted mb-5">نعم يوجد، سيتم تحويلك بشكل تلقائي للنسخة الخاصة بالأجهزة الذكية عند دخولك لموقع هنقرستيشن من جهازك الذكي.</p>

          <h1 class="display-8 lh-1 mb-3">الأسئلة التقنية</h1>
          <h1 class="display-7 lh-1 mb-3">الموقع لا يعمل على جهازي الشخصي، ما هي الاسباب؟</h1>
          <p class="lead fw-normal text-muted mb-5">أحد الاسباب المحتملة هي عدم توافق المتصفح، الموقع لا يدعم انترنت اكسبلورور بنسخته السادسة او أقل، ننصح باستخدام المتصفحات التالية، انترنت اكسبولرر بنسخته الثامنة أو التاسعة، موزيلا، فايرفوكس، سفاري أو كروم.</p>

          <app-download-app></app-download-app>
        </div>
      </div>
    </div>
  </div>
</header>
