<!-- Navigation-->
<nav class="navbar navbar-expand-lg navbar-light fixed-top shadow-sm" id="mainNav">
  <div class="container px-5">

    <a class="navbar-brand fw-bold" (click)="homePage()" [routerLink]="['/']">
      <span>
        <img style="width:150px;height: 100px;" alt="" src="assets/img/asaslogo.png">
      </span>
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
      القائمة
      <i class="bi-list"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarResponsive" *ngIf="isHomeRoute()">
      <ul class="navbar-nav ms-auto me-4 my-3 my-lg-0">
        <li class="nav-item"><a class="nav-link me-lg-3" (click)="triggerScrollTo('features')" [routerLink]="['/']" fragment="features">عن التطبيق</a></li>
        <li class="nav-item"><a class="nav-link me-lg-3" (click)="triggerScrollTo('download')" [routerLink]="['/']" fragment="download">تحميل التطبيق</a></li>
      </ul>
      <button class="btn btn-primary rounded-pill px-3 mb-2 mb-lg-0" data-bs-toggle="modal" data-bs-target="#feedbackModal">
        <span class="d-flex align-items-center">
          <span class="small">للدعم و المساعدة</span>
          <i class="bi-chat-text-fill me-2"></i>
        </span>
      </button>
    </div>
  </div>
</nav>

<router-outlet></router-outlet>

<!-- Footer-->
<footer class="bg-black text-center py-5">
  <div class="container px-5">
    <div class="text-white-50 small">
      <div class="mb-2">&copy; تطبيق اساس 2022. كل الحقوق محفوظة.</div>
      <a [routerLink]="['/UsagePolicy']">سياسة الخصوصية</a>
      <span class="mx-1">&middot;</span>
      <a [routerLink]="['/TermsAndConditions']">الشروط و الأحكام</a>
      <span class="mx-1">&middot;</span>

      <a [routerLink]="['/FAQ']">الأسئلة الشائعة</a>
    </div>
  </div>
</footer>

