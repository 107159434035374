import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-badges',
  templateUrl: './app-badges.component.html',
  styleUrls: ['./app-badges.component.css']
})
export class AppBadgesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
