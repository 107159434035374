import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { DownloadComponent } from './components/download/download.component';
import { FAQComponent } from './components/faq/faq.component';
import { HomeComponent } from './components/home-page/home/home.component';
import { LayoutComponent } from './components/shared/layout/layout.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { UsagePolicyComponent } from './components/usage-policy/usage-policy.component';

const routes: Routes = [
  {
    path: '', component: LayoutComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'FAQ', component: FAQComponent },
      { path: 'TermsAndConditions', component: TermsAndConditionsComponent },
      { path: 'UsagePolicy', component: UsagePolicyComponent },
      { path: 'DownloadApp', component: DownloadComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
