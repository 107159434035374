import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-usage-policy',
  templateUrl: './usage-policy.component.html',
  styleUrls: ['./usage-policy.component.css']
})
export class UsagePolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('html, body').animate({ scrollTop: 0 }, "slow");
  }

}
