import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
  currentRoute: string;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }


    });
  }

  public triggerScrollTo(jumpItem?: any, exactTop?: any) {
    if (jumpItem) {
      if ($('#' + jumpItem).length != 0 && $('#' + jumpItem).first().offset())
        $('html, body').animate({ scrollTop: $('#' + jumpItem).first().offset()!.top - (exactTop ? 0 : 80) }, "slow");
    }
    else
      $('html, body').animate({ scrollTop: 0 }, "slow");
  }

  homePage(): void {
    this.router.navigate(['/']);
    $('html, body').animate({ scrollTop: 0 }, "slow");
  }

  isHomeRoute(): boolean {
    if (this.currentRoute == '/UsagePolicy' ||
      this.currentRoute == '/TermsAndConditions' ||
      this.currentRoute == '/FAQ')
      return false;
    return true;

  }
}
