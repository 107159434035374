import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgHttpLoaderModule } from 'ng-http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MastheadComponent } from './components/home-page/masthead/masthead.component';
import { TestimonialComponent } from './components/home-page/testimonial/testimonial.component';
import { FeaturesComponent } from './components/home-page/features/features.component';
import { BasicFeaturesComponent } from './components/home-page/basic-features/basic-features.component';
import { CallToActionComponent } from './components/home-page/call-to-action/call-to-action.component';
import { AppBadgesComponent } from './components/home-page/app-badges/app-badges.component';
import { FeedBackComponent } from './components/home-page/feed-back/feed-back.component';
import { HomeComponent } from './components/home-page/home/home.component';
import { LayoutComponent } from './components/shared/layout/layout.component';
import { FAQComponent } from './components/faq/faq.component';
import { DownloadComponent } from './components/download/download.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { UsagePolicyComponent } from './components/usage-policy/usage-policy.component';
import { DownloadAPPComponent } from './components/shared/download-app/download-app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ASASInterceptor } from './http-interceptors/interceptor.service';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    AppComponent,
    MastheadComponent,
    TestimonialComponent,
    FeaturesComponent,
    BasicFeaturesComponent,
    CallToActionComponent,
    AppBadgesComponent,
    FeedBackComponent,
    HomeComponent,
    LayoutComponent,
    FAQComponent,
    TermsAndConditionsComponent,
    UsagePolicyComponent,
    DownloadAPPComponent,
    DownloadComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    RouterModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ASASInterceptor, multi: true, }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
